<template>
    <main class="auth_layout">
        <div class="form_block">
            <div class="auth_logo">
                <router-link :to="{name: 'login'}">
                    <!-- <LogoBlock /> -->
                    <img 
                        class="logo"
                        src="@/assets/images/ehub_logo.svg" 
                        alt="logo">
                </router-link>
            </div>
            <div class="form_wrap">
                <transition name="translate" mode="out-in">
                    <router-view v-if="authConfig" />
                </transition>
            </div>
        </div>
        <div class="img_block">

        </div>
        <RegisterDrawer />
    </main>
</template>

<script>
import { mapState } from 'vuex'
// import LogoBlock from './components/LogoBlock.vue'
import eventBus from '@/utils/eventBus'
import RegisterDrawer from '@/views/Authorization/RegisterDrawer'
export default {
    components: {
        RegisterDrawer
    },
    computed: {
        ...mapState({
            windowWidth: state => state.windowWidth
        }),
        register() {
            return this.$route?.name === 'registration' ? true : false
        },
        authConfig() {
            return this.$store.state.user.authConfig
        },
        locales() {
            return this.$i18n.availableLocales.filter(f => f !== this.$i18n.locale)
        }
    },
    metaInfo() {
        return {
            htmlAttrs: {
                class: 'auth_page'
            }
        }
    },
    data() {
        return {
            loading: false,
            formInfo: null,
            show: false
        }
    },
    created() {
        this.getInfo()
    },
    methods: {
        openRegistration() {
            eventBus.$emit('open_registration')
        },
        changeLocale(locale) {
            this.$i18n.locale = locale
            this.$router.push({params: { lang: locale }})
        },
        async getInfo() {
            try {
                this.loading = true
                await this.$store.dispatch('user/getAuthConfig')
                if(this.authConfig.reCAPTCHASiteKey) {
                    this.capGenerate()
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        },
        capGenerate() {
            const script = document.createElement('script')
            script.async = true
            script.src = `https://www.google.com/recaptcha/api.js?trustedtypes=true&render=${this.authConfig.reCAPTCHASiteKey}`
            script.onload = () => {}
            script.onerror = () => {
                console.log('%c Error occurred while loading script', 'color: #bf1432')
            }
            document.body.appendChild(script)
        }
    }
}
</script>

<style lang="scss" scoped>
.auth_layout{
    padding: 15px;
    background: #f1f2f5;
    display: flex;
    flex-wrap: wrap;
    .form_block{
        flex: 0 0 auto;
        width: 100%;
        .form_wrap{
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .img_block{
        flex: 0 0 auto;
        width: 100%;
        background: url('~@/assets/images/a_bg.jpg') no-repeat center;
        background-size: cover;
        overflow: hidden;
        border-radius: 20px;
        min-height: 600px;
        margin-top: 30px;
    }
    .auth_logo{
        margin-bottom: 20px;
        @media (max-width: 1199px) {
            display: flex;
            justify-content: center;
        }
        @media (min-width: 1200px) {
            margin-bottom: 0px;
        }
        img{
            max-width: 140px;
            min-width: 140px;
        }
    }
}
</style>